import React from "react";
import { useNavigate } from "react-router";
import "./homefooter.css";
import facebook from "../../assets/products/path14.png";
import youtube from "../../assets/products/youtube-168_svgrepo.com.png";
import linkedin from "../../assets/products/linkedin_svgrepo.com.png";
export default function NewFooter() {
  const navigate = useNavigate();
  return (
    <div className="home-footer">
      <div className="triangle-background"></div>
      <div className="social-icons">
        <img src={facebook} alt="social" className="socialicon" />
        <img src={youtube} alt="social"onClick={() =>window.open('https://www.youtube.com/@horizonbroadbandpvtltd', '_blank')}/>
        <img src={linkedin} alt="social"  onClick={() =>window.open('https://www.linkedin.com/company/horizon-broadband-pvt-ltd/', '_blank')}/>
      </div>
      <div className="nav-wrapper">
        <div className="divider"></div>

        <div className="nav-i">
          <h5 onClick={() => navigate("/")}>Home</h5>
          <h5 onClick={() => navigate("/solutions")}>Solutions</h5>
          <h5 onClick={() => navigate("/product/vivre")}>Products</h5>
          <h5 onClick={() => navigate("/customers")}>Customers</h5>
          <h5 onClick={() => navigate("/resources")}>Resources</h5>
          <h5 onClick={() => navigate("/about")}>About Us</h5>
        </div>
        <div className="divider"></div>
        <div className="contacts">
          <a
            className="elementor-button elementor-button-link elementor-size-sm"
            href="mailto:ottsales@horizonind.org?subject=Book%20A%20Demo"
          >
            <div className="email">
              <img
                src={require("../../assets/products/email-9_svgrepo.com.png")}
                style={{ width: "30px", height: "30px", marginRight: "10px" }}
              />
              <p>contact@horizonind.org</p>
            </div>
          </a>
          <div className="email">
            <img
              src={require("../../assets/products/call_svgrepo.com.png")}
              style={{ width: "30px", height: "30px", marginRight: "10px" }}
            />
            <div className="numbers">
              <p>+91 9819311615</p>
            </div>
          </div>
          <div className="location">
            <img
              src={require("../../assets/products/pin-location_svgrepo.com.png")}
              style={{ width: "30px", height: "30px", marginRight: "10px" }}
            />
            <div className="numbers">
              <p>17th Cross Rd, Sector 6,</p>
              <p> HSR Layout, Bengaluru,</p>
              <p> Karnataka 560102</p>
            </div>
          </div>
        </div>
        <div className="divider"></div>
        <div className="terms-conditions">
          <p onClick={() =>window.open('/terms&conditions', '_blank')}>Terms & conditions </p> <span> {" | "}</span>
          <p onClick={() =>window.open('/PrivacyPolicy', '_blank')}>Privacy Policy</p>
        </div>
      </div>
    </div>
  );
}
